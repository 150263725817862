import { graphql } from "gatsby"
import React, { FC } from "react"
import { Layout } from "src/components/Layout"
import { Props } from "./types"
import * as styles from "./styles.module.scss"
import Image from "src/images/global-map.png"
import { usePages } from "src/context/pages"
import { useNewsletterSubscription } from "src/hooks/useNewsletterSubscription"
import Logo from "src/images/structured-data-logo.png"

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
  },
}) => {
  const newsletter = useNewsletterSubscription("global", texts)

  const { currentPath } = usePages()

  return (
    <Layout
      title={texts.metaTitle}
      description={texts.metaDescription}
      openGraph={{
        title: texts.metaTitle,
        description: texts.metaDescription,
        url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
        images: [
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 800,
            height: 600,
            alt: "Flow logo",
          },
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 900,
            height: 800,
            alt: "Flow logo",
          },
        ],
      }}
    >
      <div className={`content-wrapper ${styles.global}`}>
        <h1 className={styles.title}>{texts.header}</h1>
        <p className={styles.subtitle}>{texts.subtitle}</p>
        <span
          className={`${styles.label} ${newsletter.status === "error" &&
            styles.error}`}
        >
          {newsletter.status === "error" ? newsletter.error : texts.labelInput}
        </span>
        <form
          className={`${styles.contentInput} ${newsletter.status === "error" &&
            styles.error}`}
          {...newsletter.formProps}
        >
          <input
            className={styles.emailInput}
            type="email"
            placeholder={texts.placeholderEmail}
            {...newsletter.inputProps}
          />
          <button
            className={`button button-second ${styles.emailButton}`}
            type="submit"
            {...newsletter.buttonProps}
          >
            {texts.signUp}
          </button>
        </form>
        <p className={styles.info}>{texts.formInfo}</p>
        <img src={Image} className={styles.image} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        metaTitle
        metaDescription
        header
        subtitle
        labelInput
        placeholderEmail
        signUp
        formInfo
        invalidEmailError
        unknownSubscriptionError
      }
    }
  }
`

export default Template
